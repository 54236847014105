import React from 'react'; 
import MyOrder from '../my-order-page-components/MyOrder/MyOrder';

function MyOrderPage() {
  return (
    <div>
        <MyOrder />
    </div>
  )
}

export default MyOrderPage