import React from 'react'; 
import HomePageHeroSection from '../home-page-components/HomePageHeroSection/HomePageHeroSection';

function HomePage() {
  return (
    <>
      <HomePageHeroSection />
    </>
  )
}

export default HomePage