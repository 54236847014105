import React from 'react'; 
import CheckoutCompWrapper from '../checkout-components/CheckoutCompWrapper/CheckoutCompWrapper';

function CheckOutPage() {
  return (
    <div >
      <CheckoutCompWrapper />
    </div>
  )
}

export default CheckOutPage