import React from 'react'

function PromotionsPage() {
  return (
    <div>
      <h1 style={{ fontFamily: "Satisfy, cursive", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>
        Coming Soon
      </h1>
    </div>
  )
}

export default PromotionsPage