import React from 'react'
import Forgetpassword from '../LoginComponent/ForgetPassword/ForgetPassword'

function ForgetPasswordPage() {
  return (
    <div>
        <Forgetpassword />
    </div>
  )
}

export default ForgetPasswordPage