import React from 'react'; 
import SpecialOffersMenu from "../special-offers-comp/SpecialOffersMenu/SpecialOffersMenu"; 
import StaticSpecialOffersMenu from '../static-special-offers/StaticSpecialOffersMenu/StaticSpecialOffersMenu';
function SpecialOffersPage() {
  return (
    <div>
      <StaticSpecialOffersMenu />
    </div>
  )
}

export default SpecialOffersPage